import { put, call } from 'redux-saga/effects';
import * as APIs from 'services/api';
import { logout } from 'redux/actions/auth';
import { updateApp } from 'redux/actions/app';

export function* refreshApp() {
  try {
    const response = yield call(APIs.getProfile);
    if (response.code === 200) {
      yield put(
        updateApp({
          profile: response.user,
          refreshed: true,
        }),
      );
    } else {
      yield put(logout());
    }
  } catch (error) {
    yield put(logout());
  }
}
