import {
    TextField,
    Typography
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import IconCalendar from '@material-ui/icons/Today'

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiPickersModal-dialog': {
            backgroundColor: 'white !important'
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#F7F7FB",
            borderRadius: 8,
            [`& fieldset`]: {
                borderRadius: 8,
                border: "1px solid #E5E3F2"
            },
        }
    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5)
    },
    adornedEnd: {
        padding: 0
    }
}));

const CustomDatePicker = ({
    label,
    className,
    required,
    placeholder,
    hideLabel,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <>
            {
                !hideLabel && label &&
                <Typography className={classes.label}><strong>{label}{required ? <span style={{ color: '#DF7C70' }}>*</span> : ''}</strong></Typography>
            }
            <KeyboardDatePicker
                {...rest}
                className={clsx(classes.root, className)}
                label={''}
                keyboardIcon={<IconCalendar />}
                KeyboardButtonProps={{
                    className: classes.adornedEnd
                }}
                placeholder={placeholder ? placeholder : "DD/MM/YYYY"}
                {
                ...hideLabel && label ? { label } : {}
                }
                cancelLabel="Annuler"
                okLabel="Valider"
                clearLabel="Effacer"
            />
        </>
    );
};

export default CustomDatePicker;
