import React, { useState } from 'react';
import {
  Popover,
  makeStyles,
  Typography,
  List,
  ListItem,
  IconButton
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SearchIcon from '@material-ui/icons/Search'
import CartIcon from '@material-ui/icons/ShoppingCart'

const useStyles = makeStyles(theme => ({
  popover: {
    borderRadius: '0px !important',
    top: '0px !important',
    boxShadow: 'none !important',
    overflow: 'hidden'
  },
  container: {
    display: 'flex',
    backgroundColor: theme.palette.app.active,
    width: 400,
    height: 400,
    padding: 20
  },
  content: {
    backgroundColor: 'white',
    flex: 1,
    padding: 20
  },
  title: {
    color: theme.palette.app.primary,
    fontWeight: 'bolder',
    fontSize: 18,
    marginBottom: 0,
  },
  scrollview: {
    maxHeight: 300,
    overflow: 'auto'
  },
  notification: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0,
    borderBottom: `3px solid ${theme.palette.app.primary}`
  },
  datetime: {
    color: 'red',
    fontSize: 14,
    marginBottom: 0,
  },
  username: {
    color: 'black',
    fontSize: 14,
    marginBottom: 0,
  },
  description: {
    color: 'black',
    fontSize: 14,
    marginBottom: 0,
  },
  toolbar: {
    textAlign: 'right'
  },
  iconbutton: {
    padding: 3,
    marginLeft: 10
  }
}));
const notifications = [
  {
    id: 1,
    datetime: 'A RECYCLER avant le 14/12/2020',
    username: 'Stagiare Alphonse Durand',
    description: 'Formation : Recyclage CACES® chariot automoteur'
  },
  // {
  //   id: 2,
  //   datetime: 'A RECYCLER avant le 14/12/2020',
  //   username: 'Stagiare Alphonse Durand',
  //   description: 'Formation : Recyclage CACES® chariot automoteur'
  // },
  // {
  //   id: 3,
  //   datetime: 'A RECYCLER avant le 14/12/2020',
  //   username: 'Stagiare Alphonse Durand',
  //   description: 'Formation : Recyclage CACES® chariot automoteur'
  // },
  // {
  //   id: 4,
  //   datetime: 'A RECYCLER avant le 14/12/2020',
  //   username: 'Stagiare Alphonse Durand',
  //   description: 'Formation : Recyclage CACES® chariot automoteur'
  // },
  // {
  //   id: 5,
  //   datetime: 'A RECYCLER avant le 14/12/2020',
  //   username: 'Stagiare Alphonse Durand',
  //   description: 'Formation : Recyclage CACES® chariot automoteur'
  // },
  // {
  //   id: 6,
  //   datetime: 'A RECYCLER avant le 14/12/2020',
  //   username: 'Stagiare Alphonse Durand',
  //   description: 'Formation : Recyclage CACES® chariot automoteur'
  // }
]
const Notifications = (props) => {
  const { id, open, anchorEl, handleClose } = props
  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{ paper: classes.popover }}
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h5" className={classes.title} paragraph>
            <FormattedMessage id="navbar.notifications.title" />
          </Typography>
          <div className={classes.toolbar}>
            <IconButton
              onClick={event => { }}
              className={classes.iconbutton}
            >
              <SearchIcon className={classes.icon} />
            </IconButton>
            <IconButton
              onClick={event => { }}
              className={classes.iconbutton}
            >
              <CartIcon className={classes.icon} style={{ fill: 'black' }} />
            </IconButton>
          </div>
          <List className={classes.scrollview}>
            {
              notifications.map(notification => (
                <ListItem key={notification.id + ''} className={classes.notification}>
                  <Typography variant="h5" className={classes.datetime} paragraph>
                    {notification.datetime}
                  </Typography>
                  <Typography variant="h5" className={classes.username} paragraph>
                    {notification.username}
                  </Typography>
                  <Typography variant="h5" className={classes.description} paragraph>
                    {notification.description}
                  </Typography>
                </ListItem >
              ))
            }
          </List>
        </div>
      </div>
    </Popover >
  );
};

export default Notifications;
