import React, { Component } from 'react';

export default class Demo extends Component<any> {

    static displayName = 'CustomLegendIconDemo';

    render() {
        const { fill } = this.props;

        return (
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="15" fill={fill} fill-opacity="0.2" />
                <circle cx="15" cy="15" r="7" fill={fill} />
            </svg>
        );
    }
}
