import { CHANGE_LOCALE, ACTION_UPDATE_CHARTS } from '../constants';

export function changeLocale(languageLocale) {
  return {
    type: CHANGE_LOCALE,
    locale: languageLocale,
  };
}
export function updateCharts(payload) {
  return {
    type: ACTION_UPDATE_CHARTS,
    payload,
  };
}
