import { createGlobalStyle } from 'styled-components';
import ITC_font from './fonts/ITCAVANTGARDESTD-BK.ttf';
import { defaults } from 'react-chartjs-2';

defaults.font.family = 'ITCAVANTGARDESTD-BK';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'ITCAVANTGARDESTD-BK';
    src: url(${ITC_font}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  html *
  {
    font-family: ITCAVANTGARDESTD-BK !important;
  }
  body {
    background-color: #F2F5FB;
  }
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  .infinite-scroll {
    padding: 10px;
    background: white;
    margin-top: 10px;
    table td, table th, table th:last-child, table td:last-child {
      padding: 4px;
    }
  }

  .truncate-list-item-text {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .truncate-list-item-text h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
    .side_menu-item {
      margin: 8px 0px;
      position: relative;
    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-height: 100%;
      padding: 16px 32px;
      justify-content: center;
      opacity: 0.5;
      &.menu-item--selected {
        background-color: #1C2753 !important;
        opacity: 1
      }
      span {
        color: white;
      }
      .menu-icon {
        margin: unset !important;
        min-width: auto;
        span {
          font-size: 30px;
        }
      }
      .menu-text{
        flex: unset !important;
        text-align: center;
        font-size: 14px !important;
        padding: 0 !important;
        text-align: left;
        margin-left: 16px;
        span {
          display: block;
          word-break: break-word;
          white-space: normal;
        }
      }
    }
  }

  .primary-bg {
    background-color: #040f40 !important;
  }

  .primary-t {
    color: #040f40  !important;
  }

  .side-bar-svg {
    width: 22px;
    height: 22px;
    color: white;
    fill: white;
  }

  .infinite-scroll {
    padding: 10px;
    background: white;
    margin-top: 10px;
    table td, table th, table th:last-child, table td:last-child {
      padding: 4px;
    }
  }
  .last_message {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .ps__rail-y {
    display: none !important;
  }
  .hidden {
    display: none;
  }
`;

export default GlobalStyle;
