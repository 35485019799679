import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import WarningIcon from '@material-ui/icons/Warning';
import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    Divider,
    CardActions,
    Button,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/actions/app';
import * as API from 'services/api';
import { appProfileRoleSelector } from 'redux/selectors';
import { ROLE_ADMIN } from 'config';
import { injectIntl, FormattedMessage } from 'react-intl';
import ModalWrap from 'components/Modals/ModalWrap';
import { authServerURL } from 'config'
import { store } from 'react-notifications-component';

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
    },
    button: {
        marginRight: 20,
        backgroundColor: '#040f40',
        padding: '10px 20px',
        color: '#fff',
        borderRadius: 15,
        '&:hover': {
            backgroundColor: '#00B0EC',
        },
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            cursor: 'default',
        },
        display: 'inline-block',
    },
    hide: {
        display: 'none',
    },
    center: {
        textAlign: 'center',
    },
}));

const ImportUsersModal = ({
    intl: { formatMessage },
    visible,
    onClose,
}) => {
    const classes = useStyles();
    const [file, setFile] = useState();

    const dispatch = useDispatch();

    const onChangeFiles = e => {
        setFile(e.target.files[0])
    };
    const removeFile = () => {
        setFile()
    };
    const isDisabled = !file;

    const onSubmit = () => {
        dispatch(setLoading(true));
        const formData = new FormData();
        formData.append('file', file);
        API.importEmployees(formData)
            .then(res => {
                dispatch(setLoading(false));
                if (res.code == 200) {
                    onClose(true)
                    store.addNotification({
                        message: 'created successfully!',
                        type: 'success',
                        container: 'top-right',
                        animationIn: ['animated', 'fadeIn'],
                        animationOut: ['animated', 'fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: true,
                        },
                    });
                } else {
                    store.addNotification({
                        message: res.message || "Internal Server Error!",
                        type: 'warning',
                        container: 'top-right',
                        animationIn: ['animated', 'fadeIn'],
                        animationOut: ['animated', 'fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: true,
                        },
                    });
                }
            })
            .catch(() => {
                store.addNotification({
                    message: "Internal Server Error!",
                    type: 'warning',
                    container: 'top-right',
                    animationIn: ['animated', 'fadeIn'],
                    animationOut: ['animated', 'fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: true,
                    },
                });
                dispatch(setLoading(false));
            });
    };

    return (
        <ModalWrap title={formatMessage({ id: 'employee.imports' })} open={visible} closeModal={onClose}>
            <input
                type="file"
                id="users-file"
                accept=".xlsx, .xls, .csv"
                className={classes.hide}
                onChange={onChangeFiles}
            />

            <React.Fragment>
                {file != null && (
                    <Typography component="p">{file?.name}</Typography>
                )}
                <br />
                {file == null ? (
                    <label htmlFor="users-file" className={classes.button} style={{ textDecoration: 'underline' }}>
                        <FormattedMessage id="select_file" />
                    </label>
                ) : (
                    <Button
                        onClick={removeFile}
                        variant="contained"
                        classes={{ root: classes.button }}
                    >
                        <FormattedMessage id="remove" />
                    </Button>
                )}
            </React.Fragment>
            <br/><br/>
            <CardActions style={{ justifyContent: "space-around" }}>
                <Button
                    variant="contained"
                    classes={{ root: classes.button }}
                    onClick={() => {
                        window.open(`${authServerURL}doc/employees.xlsx`, '_blank')
                    }}
                >
                    <FormattedMessage id="employee.downloadmodel" />
                </Button>
                <Button
                    variant="contained"
                    classes={{ root: classes.button }}
                    disabled={isDisabled}
                    onClick={onSubmit}
                >
                    <FormattedMessage id="upload" />
                </Button>
            </CardActions>
        </ModalWrap>
    );
};

ImportUsersModal.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }),
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default injectIntl(ImportUsersModal);
