import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import { Select, FormControl, MenuItem, TextField } from '@material-ui/core'
import { injectIntl } from 'react-intl';
import { ReactComponent as IconRadioOn } from 'assets/svgs/radio_on.svg';
import { ReactComponent as IconRadioOff } from 'assets/svgs/radio_off.svg';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 530,
        margin: 'auto',
        backgroundColor: 'white',
        borderRadius: theme.spacing(1),
        border: '1px solid #D3DAF6',
        boxShadow: 'none',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        color: 'black'
    },
    iconButton: {
        padding: 10,
        color: '#AAB1CF',
        paddingRight: 0
    },
    divider: {
        height: 28,
        margin: 4,
    },
    formControl: {
        minWidth: 165,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        fill: 'white',
        color: '#0E1927'
    },
    dropIcon: {
        fill: theme.palette.app.primary
    },
    innerinput: {
        color: 'black',
        '&::placeholder': {
            color: '#AAB1CF'
        }
    },
    menupaper: {
        top: `68px !important`,
        borderRadius: 10
    },
    radio: {
        marginBottom: 2
    },
    selected: {
        backgroundColor: 'transparent !important'
    },
    autocompletePaper: {
        borderRadius: 10
    },
    autocompletePopup: {
        top: `10px !important`,
    }
}));
const items = [
    { value: 1, label: 'Tout Apave' },
    { value: 2, label: 'Solutions digitales' },
    { value: 3, label: 'Formation' },
    { value: 4, label: 'Prestations' },
    { value: 5, label: 'Implantations' }
]
function CustomizedInputBase({ intl: { formatMessage } }) {
    const classes = useStyles();
    const [type, setType] = useState(1)
    return (
        <Paper component="form" className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="menu">
                <SearchIcon />
            </IconButton>
            <Autocomplete
                id="combo-box-demo"
                options={top100Films}
                getOptionLabel={(option) => option.title}
                style={{ flex: 1, marginRight: 12 }}
                forcePopupIcon={false}
                renderInput={(params) => <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        classes: { input: classes.innerinput }
                    }}
                    placeholder={formatMessage({ id: "topbar.searchbar" })}
                    className={classes.input}
                />}
                classes={{
                    paper: classes.autocompletePaper,
                    popper: classes.autocompletePopup
                }}
            />
            <Divider className={classes.divider} orientation="vertical" />
            <Select
                className={classes.formControl}
                classes={{ icon: classes.dropIcon }}
                disableUnderline
                value={type}
                onChange={e => setType(e.target.value)}
                MenuProps={{
                    classes: {
                        paper: classes.menupaper,
                    }
                }}
                renderValue={(val) => {
                    const item = items.find(el => el.value == val)
                    return item?.label || ''
                }}
            >
                {
                    items.map(item => {
                        return <MenuItem key={item.value} value={item.value} classes={{ selected: classes.selected }}>
                            {
                                item.value == type ?
                                    <IconRadioOn className={classes.radio} /> :
                                    <IconRadioOff className={classes.radio} />
                            }
                            &nbsp;&nbsp;
                            {item.label}
                        </MenuItem>
                    })
                }
            </Select>
        </Paper>
    );
}
export default injectIntl(CustomizedInputBase);

const top100Films = [
    { title: 'ALERT Santé Sécurité', },
    { title: 'ALERT Environnement', },
    { title: 'CHECK Santé Sécurité', },
    { title: 'CHECK Environnement', },
    { title: 'CHECK Access', },
    { title: 'CHECK Immo', },
    { title: 'PILOT Santé Sécurité', },
    { title: 'PILOT Exploitation', },
    { title: 'PILOT Veille', },
    { title: 'PILOT Immo', }
];