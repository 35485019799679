import React, { useEffect, useState } from 'react';
import { makeStyles, Button, TableContainer, TableHead, TableRow, TableCell, Table, TableBody } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import * as API from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { appLoadingSelector } from 'redux/selectors';
import { setLoading } from 'redux/actions/app';
import moment from 'moment'
import { get10LastLetters } from 'utils/validations'
import { CustomTable } from 'components'
import VisibilityIcon from '@material-ui/icons/Visibility';
import OrderDetails from './detail';
import { LEAD_EXTERNAL_STATUSES } from 'config';
import EmptyPage from 'components/EmptyPage';

const useStyles = makeStyles(theme => ({
  placeholder: {
    paddingLeft: theme.spacing(3)
  },
  table: {
    backgroundColor: 'white'
  },
  cartBtn: {
    borderRadius: 4,
    color: "#05A969"
  },
}));

const Orders = ({
  intl: { formatMessage },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([])
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(setLoading(true));
    API.getMyOrders({})
      .then(res => {
        dispatch(setLoading(false));
        if (res.data && res.data.length > 0) {
          setOrders(res.data.map(item => ({
            ...item.source,
            ...item,
            id: item.id
          })))
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, []);
  const tableColumns = [
    {
      name: 'id',
      label: "COMMANDE",
      options: {
        display: true,
        sort: false,
        customBodyRender: (value) => get10LastLetters(value)
      }
    },
    {
      name: 'createdAt',
      label: "DATE",
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY")
      }
    },
    {
      name: 'source.title',
      label: "PRODUCT",
      options: {
        display: true,
        sort: true
      }
    },
    {
      name: 'source.quantity',
      label: "Quantité".toUpperCase(),
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => (value || 1) + " produits"
      }
    },
    {
      name: 'price',
      label: "MONTANT",
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => value + '€'
      }
    },
    {
      name: 'statusExternal',
      label: "STATUT",
      options: {
        display: true,
        sort: true,
        customBodyRender: (value) => formatMessage({
          id: 'action.status.' + (!LEAD_EXTERNAL_STATUSES.includes(value) ? LEAD_EXTERNAL_STATUSES[0] : value),
        }),
      }
    },
    {
      name: 'actions',
      label: "ACTIONS",
      options: {
        display: true,
        sort: false,
        customBodyRender: (value, tableMeta) => <Button
          className={classes.cartBtn}
          style={{ textTransform: 'none' }}
          startIcon={<VisibilityIcon />}
          onClick={() => {
            const id = tableMeta.rowData[0];
            const order = orders.find(item => item.id == id)
            setOpenModal(order)
          }}
        >
          {'Détails'}
        </Button>
      }
    }
  ];

  return (
    <div>
      {
        orders.length > 0 ?
          <CustomTable
            columns={tableColumns}
            data={orders}
            options={{
              pagination: false,
              print: false,
              download: false,
              selectableRows: 'none',
              search: true,
              filter: true,
              viewColumns: false,
              elevation: 0,
              filter: false,
              search: false,
              textLabels: {
                body: {
                  noMatch: formatMessage({
                    id: 'muitable.placeholder.nomatch_records',
                  }),
                }
              }
            }}
          /> :
          <EmptyPage title="Vous n’avez réalisé aucune commande."/>
      }
      {
        openModal &&
        <OrderDetails
          open={openModal}
          handleClose={(data) => { setOpenModal(data) }}
        />
      }
    </div>
  );
};

export default injectIntl(Orders);
