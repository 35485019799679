import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { CardContent, Typography, List, Card, IconButton, Button, Grid, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import useRouter from 'utils/useRouter';
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl';
import { setContactModal } from 'redux/actions/app'
import { ReactComponent as IconEmoji } from 'assets/svgs/emoji.svg';
import {
    loggedInSelector,
    appProfileSelector
} from 'redux/selectors';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: 'white',
        borderRadius: 15
    },
    emojiIcon: {
        width: 60,
        height: 60,
        marginBottom: theme.spacing(1)
    },
    button: {
        textTransform: 'none',
        borderRadius: 100
    },
    emptyContent: {
        minHeight: "calc(100vh - 280px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
}));

function EmptyPage({ intl: { formatMessage }, title }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();
    const isLoggedIn = useSelector(loggedInSelector);

    return (
        <Card className={classes.card}>
            <CardContent className={classes.emptyContent}>
                <br />
                <br />
                <IconEmoji className={classes.emojiIcon} />
                <Typography variant="h3"><strong>{title ? title : "Vous n’avez aucun produit dans votre panier."}</strong></Typography>
                <Typography style={{ marginTop: 4 }}>Notre équipe reste a votre disposition si vous avez des questions.</Typography>
                <br />
                <br />
                <br />
                <Grid container justify='center'>
                    <Button variant='contained' color="primary" className={classes.button} style={{ backgroundColor: '#05A969' }}
                        onClick={() => {
                            router.history.push(isLoggedIn ? "/catalogue" : "/invite")
                        }}
                    >Découvrir nos produits</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant='contained' color="primary" className={classes.button}
                        onClick={() => dispatch(setContactModal(true))}
                    >Poser une question</Button>
                </Grid>
                <br />
                <br />
            </CardContent>
        </Card>
    );
}
export default injectIntl(EmptyPage)