import React, { useState, useEffect } from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
    position: 'relative',
  },
  title: {
    fontSize: 18,
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
}));

const Header = ({ className, title }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.any,
  title: PropTypes.string.isRequired,
};

Header.defaultProps = {
  className: null,
};

export default Header;
