import { makeStyles } from '@material-ui/styles';
import React, { useState, useCallback } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import {
  Button, IconButton, Typography, Grid,
  Modal,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  colors,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import CameraIcon from '@material-ui/icons/CameraAlt';
import UploadIcon from '@material-ui/icons/Publish';
import Resizer from "react-image-file-resizer";
import CloseIcon from '@material-ui/icons/Close'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import getCroppedImg from './cropImage'

const useStyles = makeStyles(theme => ({
  root: {},
  image: {
    maxWidth: '100%',
    minWidth: 75,
    height: 75,
    marginRight: theme.spacing(2),
    borderRadius: 10,
    objectFit: 'contain'
  },
  label: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 2)
  },
  button: {},
  close: {
    padding: 0,
    position: 'absolute',
    right: 0,
    top: -10
  },
  wrapper: {
    position: 'relative',
    display: 'inline-block'
  },
  cameraIcon: {
    width: 75,
    height: 75,
    backgroundColor: '#ECEFF8',
    borderRadius: 10,
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadBtn: {
    borderRadius: 30,
    padding: 0
  },
  uploadIcon: {
    width: 15,
    height: 15,
    marginRight: 4
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    cursor: 'pointer',
    width: theme.spacing(3),
    height: theme.spacing(3),

    fill: colors.blueGrey[900],

    '&:hover': {
      opacity: 0.5
    }
  },
  actions: {
    flexDirection: 'row-reverse'
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 65,
    },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 32,
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      margin: '0 16px',
    },
  },
}));
const resizeFile = (file, size) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      size,
      size,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
const ImageSelector = ({
  value,
  fieldValue,
  onChange,
  id: fieldId,
  name,
  disabled = false,
  minwidth,
  max
}) => {
  const classes = useStyles();
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState();
  const [showCrop, setShowCrop] = useState(false);
  const [isCrop, setIsCrop] = useState(true);

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [originFile, setOriginFile] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        file,
        croppedAreaPixels,
        rotation
      )

      const response = await fetch(croppedImage);
      const data = await response.blob();
      const new_file = new File([data], name, {
        type: data.type
      });

      onChange({
        file: new_file,
        fileContent: croppedImage
      });
      setVisible(false)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const onClose = () => {
    setVisible(false)
  }
  const onConfirm = () => {
    if (!isCrop) {
      setVisible(false)
      onResizeFile(file)
    } else {
      if (!showCrop)
        setShowCrop(true)
      else {
        showCroppedImage()
      }
    }
  }
  const onResizeFile = async () => {
    try {
      const image = await resizeFile(originFile, max);
      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = async () => {
          onChange({
            file: image,
            fileContent: fr.result
          });
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(image);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item style={{ position: 'relative' }}>
        {
          !fieldValue ? <div className={classes.cameraIcon}>
            <CameraIcon />
          </div> :
            <img
              alt="select image"
              className={classes.image}
              src={
                typeof fieldValue === 'string'
                  ? fieldValue
                  : value[`${fieldId}-content`]
              }
            />
        }
        {fieldValue && !disabled && (
          <IconButton
            className={classes.close}
            disabled={disabled}
            onClick={() => {
              onChange({
                [fieldId]: null,
                [`${fieldId}-content`]: ''
              });
            }}>
            <CancelIcon />
          </IconButton>
        )}
      </Grid>
      <Grid item>
        <div className={classes.wrapper}>
          <Button disabled={!!fieldValue || disabled} variant="outlined" size="small" className={classes.uploadBtn}>
            <input
              accept="image/*"
              className="hidden"
              id={`file-${fieldId}`}
              onChange={event => {
                if (event.target.files.length > 0) {
                  const [selectedFile] = event.target.files;
                  const fr = new FileReader();
                  fr.onload = function () {
                    const img = new Image();
                    img.onload = async () => {
                      // if (max && img.width > max) {
                      //   setOriginFile(selectedFile)
                      //   setFile(fr.result)
                      //   setIsCrop(true)
                      //   setShowCrop(false)
                      //   setVisible(true)
                      //   return;
                      // }
                      if (minwidth && img.width < minwidth) {
                        return alert(`Width must be at least ${minwidth}px.`);
                      }
                      onChange({
                        file: selectedFile,
                        fileContent: fr.result
                      });
                    };
                    img.src = fr.result;
                  };
                  fr.readAsDataURL(selectedFile);
                  event.target.value = null;
                }
              }}
              type="file"
            />
            <label
              className={classes.label}
              htmlFor={fieldValue ? '' : `file-${fieldId}`}>
              <UploadIcon className={classes.uploadIcon} />
              {name || 'Upload'}
            </label>
          </Button>
        </div>
      </Grid>
      <Modal onClose={onClose} open={visible}>
        <Card className={classes.modal}>
          <CardHeader
            component={() => (
              <div className={classes.header}>
                <Typography variant="h5">{"Image Picker"}</Typography>
                <CloseIcon className={classes.closeIcon} onClick={onClose} />
              </div>
            )}
          />
          <Divider />
          <CardContent className={classes.content}>{
            !showCrop ?
              <FormControl component="fieldset">
                <RadioGroup aria-label="group" name="group" value={isCrop} onChange={(e) => {
                  setIsCrop(e.target.value == "true" ? true : false)
                }}>
                  <FormControlLabel value={true} control={<Radio style={{ padding: 0, margin: 8 }} />} label="Crop to correct size" />
                  <FormControlLabel value={false} control={<Radio style={{ padding: 0, margin: 8 }} />} label="Resize keeping original radio" />
                </RadioGroup>
              </FormControl> :
              <div>
                <div className={classes.cropContainer}>
                  <Cropper
                    image={file}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    aspect={4 / 4}
                    onCropChange={setCrop}
                    onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropSize={{ width: max, height: 150 }}
                  />
                </div>
                <div className={classes.controls}>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Zoom
                    </Typography>
                    <Slider
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      classes={{ root: classes.slider }}
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                  </div>
                  <div className={classes.sliderContainer}>
                    <Typography
                      variant="overline"
                      classes={{ root: classes.sliderLabel }}
                    >
                      Rotation
                    </Typography>
                    <Slider
                      value={rotation}
                      min={0}
                      max={360}
                      step={1}
                      aria-labelledby="Rotation"
                      classes={{ root: classes.slider }}
                      onChange={(e, rotation) => setRotation(rotation)}
                    />
                  </div>
                </div>
              </div>
          }</CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <Button onClick={onConfirm} variant="contained" color="primary">
              {"Confirm"}
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Grid>
  );
};

export default ImageSelector;
