import { useContext } from 'react';
import { ConversationContext } from './provider';

export default function useConversationContext() {
  const context = useContext(ConversationContext);
  if (!context) {
    throw new Error('useConversationContext must be used within a ConversationProvider');
  }
  return context;
}
