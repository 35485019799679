import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Grid, Button } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import * as API from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { appLoadingSelector } from 'redux/selectors';
import { setLoading } from 'redux/actions/app';
import moment from 'moment'
import EmployeeModal from './employeemodal'
import ImportModal from './importmodal'

const useStyles = makeStyles(theme => ({
  placeholder: {
    paddingLeft: theme.spacing(3)
  },
  table: {
    backgroundColor: 'white'
  },
  button: {
    marginLeft: theme.spacing(2),
    display: 'block',
    borderRadius: 4
  },
}));

const Employees = ({
  intl: { formatMessage },
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [visibileNew, setVisibileNew] = useState();
  const [visibileImport, setVisibileImport] = useState();

  const [orders, setOrders] = useState([])

  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    dispatch(setLoading(true));
    API.listEmployees({})
      .then(res => {
        dispatch(setLoading(false));
        if (res.data && res.data.length > 0) {
          setOrders(res.data)
        }
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }
  return (
    <div>
      <Grid container justify='flex-end'>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => setVisibileNew(true)}
        >
          {formatMessage({ id: "employee.add" })}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => setVisibileImport(true)}
        >
          {formatMessage({ id: "employee.imports" })}
        </Button>
      </Grid>
      <br />
      {
        orders && orders.length > 0 ?
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tablehead}>
                <TableRow>
                  <TableCell align="left"><strong>{formatMessage({ id: "employee.gender" })}</strong></TableCell>
                  <TableCell align="left"><strong>{formatMessage({ id: "employee.firstName" })}</strong></TableCell>
                  <TableCell align="left"><strong>{formatMessage({ id: "employee.lastName" })}</strong></TableCell>
                  <TableCell align="left"><strong>{formatMessage({ id: "employee.birth" })}.</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  orders.map(item => {
                    return <TableRow key={item.id}>
                      <TableCell>{formatMessage({ id: "cart.gender." + item.gender })}</TableCell>
                      <TableCell>{item.firstName}</TableCell>
                      <TableCell>{item.lastName}</TableCell>
                      <TableCell>{moment(item.birth).format('DD/MM/YYYY')}</TableCell>
                    </TableRow>
                  })
                }
              </TableBody>
            </Table>
          </TableContainer> :
          <Typography className={classes.placeholder}>
            {'No employees'}
          </Typography>
      }
      {
        visibileNew &&
        <EmployeeModal
          visible={visibileNew}
          onClose={(refresh) => {
            setVisibileNew(false)
            if (refresh) {
              loadData();
            }
          }}
        />
      }
      {
        visibileImport &&
        <ImportModal
          visible={visibileImport}
          onClose={(refresh) => {
            setVisibileImport(false)
            if (refresh) {
              loadData();
            }
          }}
        />
      }
    </div>
  );
};

export default injectIntl(Employees);
