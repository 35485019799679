import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { serverURL } from 'config';

var link = document.querySelector("link[rel~='icon']");

if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
}
if (serverURL.includes("test.")) {
    link.href = `/red.ico`;
} else if (serverURL.includes("pp.")) {
    link.href = `/orange.ico`;
} else if (serverURL.includes("localhost")) {
    link.href = `/red.ico`;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
