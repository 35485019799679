import { WELDING_APP_ID, ROLE_ADMIN } from 'config';

// auth
export const authSelector = state => state.auth;
export const loggedInSelector = state => authSelector(state).loggedIn;

// language
export const localeSelector = state => state.language.locale;

// app
export const appSelector = state => state.app;
export const appProfileSelector = state => appSelector(state).profile;
export const appRefreshedSelector = state => appSelector(state).refreshed;
export const appLoadingSelector = state => appSelector(state).loading;

export const appProfileRoleSelector = state => appProfileSelector(state).role;
export const appProfileWeldingEnabledSelector = state => {
  const { permissions } = state.app.profile;
  const weldingPermission = permissions.find(p => p.appId === WELDING_APP_ID);
  if (weldingPermission && weldingPermission.allowed) {
    return true;
  }
  return false;
};

export const appProfileRoleIsAdminSelector = state => {
  const role = appProfileRoleSelector(state);
  return role === ROLE_ADMIN;
};
