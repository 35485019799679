import {
    TextField,
    Typography
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#F7F7FB",
        borderRadius: 8,
        [`& fieldset`]: {
            borderRadius: 8,
            border: "1px solid #E5E3F2"
        },
    },
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(0.5),
        color: '#061024',
        fontWeight: '600'
    },
    disabled: {
        color: theme.palette.app.primary
    }
}));

const CustomTextInput = ({
    label,
    className,
    required,
    labelStyle,
    endAdornment,
    startAdornment,
    ...rest
}) => {
    const classes = useStyles();
    return (
        <>
            {
                label &&
                <Typography className={clsx(classes.label, labelStyle)}>{label}{required ? <span style={{ color: '#DF7C70' }}>*</span> : ''}</Typography>
            }
            <TextField
                {...rest}
                className={clsx(classes.root, className)}
                label={''}
                InputProps={{ classes: { disabled: classes.disabled }, endAdornment, startAdornment, ...rest.InputProps }}
            />
        </>
    );
};

export default CustomTextInput;
