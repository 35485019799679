import { combineReducers } from 'redux';
import app from './app';
import auth from './auth';
import language from './language';
import cart from './cart';

export default combineReducers({
  app,
  auth,
  language,
  cart
});
