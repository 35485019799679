import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { makeStyles, colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  iconContainer: {
    userSelect: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0.8),

    borderRadius: theme.spacing(0.8),
    borderColor: colors.grey[700],
    borderStyle: 'solid',
    borderWidth: 1,
    cursor: 'pointer',
    '& svg': {
      width: theme.spacing(2),
      height: theme.spacing(2),
      fill: colors.grey[700],
      '& path': {
        fill: colors.grey[700],
      },
    },
    '&:hover': {
      opacity: 0.5,
    },
  },
  active: {
    borderColor: theme.palette.app.primary,
    '& svg': {
      fill: theme.palette.app.primary,
      '& path': {
        fill: theme.palette.app.primary,
      },
    },
  },
}));

const CustomIconButton = ({ active, icon, onClick }) => {
  const classes = useStyles();
  return (
    <span
      className={clsx(classes.iconContainer, active ? classes.active : null)}
      onClick={onClick}
    >
      {icon}
    </span>
  );
};

CustomIconButton.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func,
};

CustomIconButton.defaultProps = {
  active: false,
  onClick: () => {},
};

export default CustomIconButton;
