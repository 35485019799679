import React from 'react';
import {
  makeStyles,
  colors,
  Typography,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  Button,
  Grid
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  field: {
    flex: 1
  },
  title: {
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    color: theme.palette.app.primary,
    textTransform: 'uppercase'
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 15,
    padding: '16px !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E3F2',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    color: '#0E1927'
  },
  button: {
    borderRadius: theme.spacing(0.5),
    padding: '4px 16px',
    textTransform: 'none',
    marginTop: theme.spacing(2)
  },
}));

const Notifications = ({
  data: { notification_newdoc, notification_newsession },
  handleChange,
  intl: { formatMessage },
  disabled,
  onSave
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.header}>
          <div>
            <Typography variant="h4" className={classes.title}>
              Mes notifications
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              Sélectionner les notifications que vous souhaitez recevoir.
            </Typography>
          </div>
        </div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={notification_newdoc}
              name="notification_newdoc"
              onChange={handleChange}
            />
          }
          label={formatMessage({ id: 'notifications.newdoc' })}
          className={classes.field}
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={notification_newsession}
              name="notification_newsession"
              onChange={handleChange}
            />
          }
          label={formatMessage({ id: 'notifications.newsession' })}
          className={classes.field}
        />

        <Grid container justify="flex-start" >
          <Button variant="contained" classes={{ root: classes.button }} disabled={disabled}
            onClick={onSave}>
            <FormattedMessage id="save" />
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default injectIntl(Notifications);
