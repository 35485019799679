import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, CardHeader, Paper, IconButton, Typography } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close'

const ModalWrap = ({ title, closeModal, rightComponent, headerDarkmode }) => {
    const useStyles = React.useCallback(makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.app.primary,
            position: 'sticky',
            top: 0,
            zIndex: 2,
            borderRadius: 0
        },
        headerAction: {
            marginTop: 0,
            marginBottom: 0
        },
        cardHeader: {
            backgroundColor: theme.palette.app.primary
        },
        headerTitle: {
            color: 'white',
            fontSize: 20,
            lineHeight: '25px'
        },
        title: {
            color: 'white',
            fontSize: 20,
            lineHeight: '25px'
        }
    })), []);
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <CardHeader
                className={classes.cardHeader}
                action={
                    <div style={{ display: 'flex' }}>
                        {rightComponent}
                        <IconButton aria-label="close" onClick={closeModal} style={{ padding: 8 }}>
                            <IconClose style={{ fill: 'white' }}/>
                        </IconButton>
                    </div>
                }
                title={<Typography className={classes.title}>{title}</Typography>}
                classes={{ action: classes.headerAction }}
            />
        </Paper >
    );
};
export default ModalWrap;
