import { formatDate, parseDate } from 'react-day-picker/moment';
import _ from 'lodash';

export const MONTHS_STRINGS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Aout',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const WEEKDAYS_LONG = [
  'Dimanche',
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
];

export const WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];

export const arrayToObject = (arr, key = 'appId') => {
  const obj = {};
  arr.forEach(element => {
    obj[element[key]] = element;
  });
  return obj;
};

export function formatDateYYYYMMDD(date) {
  return formatDate(new Date(date), 'YYYY-MM-DD');
}

export function formatDateDDMMYYYY(date) {
  return formatDate(new Date(date), 'DD-MM-YYYY');
}

export function getDateFromYYYYMMDD(str) {
  return parseDate(str, 'YYYY-MM-DD');
}

export function extractNameFromKey(key) {
  const sps = String(key).split('/');
  if (sps[sps.length - 1] === '') {
    return sps[sps.length - 2];
  }
  return sps[sps.length - 1];
}

export function getDDMSYYYY(str) {
  const date = new Date(str);
  return `${date.getDate()} ${MONTHS_STRINGS[
    date.getMonth()
  ]} ${date.getFullYear()}`;
}

export function includesSearchWord(title, searchWord) {
  if (searchWord === '') {
    return true;
  }
  return (
    String(title).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().indexOf(String(searchWord).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()) >= 0
  );
}
export function groupBy(items, key) {
  return items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }),
    {},
  );
}
export function mapFiltersFromTableData(arrays, keys) {
  let result = {}
  for (let i = 0; i < keys.length; i++) {
    const values = arrays.map(item => _.get(item, keys[i])).filter(item => !!item)
    result[keys[i]] = values.filter(function (item, pos) {
      return values.indexOf(item) == pos;
    })
  }
  return result
}
export function parseURLQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}
export function removeDuplicateFromArray(a, key) {
  return a.filter((value, index, self) =>
    index === self.findIndex((t) => (
      key ? t[key] == value[key] : t == value
    ))
  )
}
export function getSiretsFromProfile(profile) {
  let result = profile.establishments.map(item => item.siret ? item.siret.trim() : item)
  if (profile.siret) result.push(profile.siret.trim())
  return result
}

export function fixedPriceFormat(str) {
  return str ? Number(str) : "";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}