import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid,
    Line, LineChart, Legend, ResponsiveContainer, PieChart, Pie, Bar,
    BarChart, Cell, RadarChart, Radar, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ComposedChart,
    RadialBarChart, RadialBar, Treemap, Sector
} from 'recharts';
import CustomLegendIcon from './legendicon';
import { Typography } from '@material-ui/core'
const COLOR_PATTERNS = ['#4285F4', '#D65140', '#8254A9', '#34A853', '#F6B646', '#284C88'];
const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
}));
const data07 = [
    { name: '18-24', uv: 31.47, pv: 2400, fill: '#8884d8' },
    { name: '25-29', uv: 26.69, pv: 4567, fill: '#83a6ed' },
    { name: '30-34', uv: 15.69, pv: 1398, fill: '#8dd1e1' },
    { name: '35-39', uv: 8.22, pv: 9800, fill: '#82ca9d' },
    { name: '40-49', uv: 8.63, pv: 3908, fill: '#a4de6c' },
    { name: '50+', uv: 2.63, pv: 4800, fill: '#d0ed57' },
    { name: 'unknown', uv: 6.67, pv: 4800, fill: '#ffc658' }
];
const data5 = [
    { value: 'Apple', color: '#ff7300', legendIcon: <CustomLegendIcon /> },
    { value: 'Samsung', color: '#bb7300', legendIcon: <CustomLegendIcon /> },
    { value: 'Huawei', color: '#887300', legendIcon: <CustomLegendIcon /> },
    { value: 'Sony', color: '#667300', legendIcon: <CustomLegendIcon /> },
];
const Chart = props => {
    const { loading, data, title, className, innerRadius, ...rest } = props;
    const [activeIndex, setActiveIndex] = useState(0)
    const classes = useStyles();
    const theme = useTheme();

    const dataProp = data.map((item, index) => ({
        name: item.label,
        value: item.value,
        fill: COLOR_PATTERNS[index % COLOR_PATTERNS.length],
    }))
    const legendList = data.map((item, index) => ({
        value: item.label,
        color: COLOR_PATTERNS[index % COLOR_PATTERNS.length],
        legendIcon: < CustomLegendIcon fill={COLOR_PATTERNS[index % COLOR_PATTERNS.length]} />
    }))
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Typography variant='h6' style={{ textAlign: 'center' }}><strong>{title}</strong></Typography>
            {
                !loading &&
                <ResponsiveContainer>
                    <PieChart width={400} height={400}>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={(_, index) => {
                                setActiveIndex(index)
                            }}
                        >
                            {
                                data.map((entry, index) => <Cell fill={COLOR_PATTERNS[index % COLOR_PATTERNS.length]} />)
                            }
                        </Pie>
                        {/* <Legend iconSize={15} width={140} height={35} payload={legendList} layout="vertical" verticalAlign="middle"
                            wrapperStyle={{
                                lineHeight: '35px',
                                left: 300,
                                fontSize: 14,
                            }}
                        /> */}
                        <Tooltip data content={(props) => {
                            const data = dataProp[props.label]
                            if (data)
                                return <Typography style={{ backgroundColor: 'white', padding: 10, borderRadius: 4, boxShadow: '0px 8px 35px rgba(6, 56, 63, 0.1)' }}> {`${dataProp[props.label].name}: ${dataProp[props.label].value}`}</Typography>
                            return ''
                        }} payload={legendList} />
                    </PieChart>
                </ResponsiveContainer>
            }
        </div>
    );
};

Chart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.array.isRequired,
};
function areEqual(prevProps, nextProps) {
    if (prevProps.data != nextProps.data || prevProps.title != nextProps.title) return false
    return true
}
export default React.memo(Chart, areEqual);
const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value, label } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 4} y={ey+3} textAnchor={textAnchor} fill={fill} font-size="0.7em">{`${label}: ${value}`}</text>
        </g>
    );
};