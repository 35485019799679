import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { localeSelector } from 'redux/selectors';

const LanguageProvider = props => {
  const locale = useSelector(localeSelector);
  return (
    <IntlProvider
      key={locale}
      locale={locale}
      messages={props.messages[locale]}>
      {React.Children.only(props.children)}
    </IntlProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
  locale: PropTypes.string,
  messages: PropTypes.object
};

export default LanguageProvider;
