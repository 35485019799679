import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { listRequests, listMessages } from 'services/api'
import { appProfileSelector } from 'redux/selectors';

export const ConversationContext = createContext();
export const ConversationProvider = ({ children }) => {
    const profile = useSelector(appProfileSelector);
    const [channels, setChannels] = useState([])
    const [conversation, setConversation] = useState(null);
    const [messages, setMessages] = useState([]);
    const [refresh, setRefresh] = useState(new Date());

    const [connectState, setConnectState] = useState()
    const isLoggedIn = true;
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [chatClient, setChatClient] = useState();
    const [clients, setClients] = useState([])
    const [suppliers, setSuppliers] = useState([]);


    useEffect(() => {
        if (profile) {
            loadChannels()
        }
    }, [profile])

    useEffect(() => {
        loadMessages()
    }, [conversation])


    const loadChannels = () => {
        listRequests({}).then(res => {
            if (res.code == 200) {
                setChannels(res.data)
            }
        })
    }
    const loadMessages = () => {
        if (conversation) {
            listMessages({ requestId: conversation.id }).then(res => {
                if (res.code == 200) {
                    setMessages(res.data.reverse())
                }
            })
        }
    }
    return (
        <ConversationContext.Provider
            value={{
                loadChannels,
                channels,
                conversation,
                setConversation,
                loadMessages,
                messages,
                refresh,
                setRefresh,


                loading,
                users, clients, suppliers
            }}
        >
            {children}
        </ConversationContext.Provider>
    );
};
