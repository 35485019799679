import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Line } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'top',
        }
    },
    scales: {
        y:
        {
            ticks: {
                beginAtZero: true,
                min: 0,
                precision: 0
            },
        }
    }
};

const useStyles = makeStyles(() => ({
    root: {
        position: 'relative',
    },
}));

const Chart = props => {
    const { loading, data, className, ...rest } = props;

    const classes = useStyles();
    const theme = useTheme();
    return (
        <div
            {...rest}
            className={clsx(classes.root, className)}
        >
            {
                !loading &&
                <Line
                    data={data}
                    options={options}
                />
            }
        </div>
    );
};

Chart.propTypes = {
    className: PropTypes.string
};

export default Chart;
