import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles, Hidden, Divider, Typography, Modal, Card, CardHeader, CardContent, TextField, CardActions, Button, colors, Grid } from '@material-ui/core';
import { appProfileSelector } from 'redux/selectors';
import { setLoading } from 'redux/actions/app';
import { useSelector, useDispatch } from 'react-redux';
import * as API from 'services/api';
import { store } from 'react-notifications-component';
import CloseIcon from '@material-ui/icons/Close';
import { loggedInSelector, appLoadingSelector } from 'redux/selectors';
import { isEmail } from 'utils/validations';
import MuiPhoneNumber from 'material-ui-phone-number';

const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  mainContent: { marginTop: theme.spacing(2), minHeight: '100vh' },
  togglebtngroup: {
    marginBottom: theme.spacing(4),
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block'
  },
  togglebtn: {
    border: '1px solid #030F40 !important',
    borderRadius: '30px !important',
    marginRight: theme.spacing(2),
    padding: '10px 30px',
    color: `${theme.palette.app.primary} !important`
  },
  selectedBtn: {
    fontWeight: 'bold !important',
    color: `white !important`,
    backgroundColor: `${theme.palette.app.primary} !important`
  },
  btnLabel: {
    fontSize: '12px !important'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 800,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    borderRadius: 10
  },
  field: {
    marginBottom: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 22,
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),

    fill: 'white',

    '& path': {
      fill: 'white',
    },
  },
  actions: {
    marginBottom: theme.spacing(1),
    justifyContent: 'center'
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    backgroundColor: theme.palette.app.primary
  },
  icon: {
    width: theme.spacing(2.1),
    height: theme.spacing(2.1),
    marginRight: theme.spacing(1),

    fill: 'white',
    '& path': {
      fill: 'white',
    },
  },
  headertitle: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '40px',
    marginRight: theme.spacing(2)
  },
  subtitle: {
    color: '#464F73'
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.app.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  }
}));

const ContactModal = ({ intl: { formatMessage }, visible, onClose, appId }) => {
  const classes = useStyles();
  const profile = useSelector(appProfileSelector);
  const dispatch = useDispatch();
  const [question, setQuestion] = useState('')
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  })
  const isLoggedIn = useSelector(loggedInSelector);

  useEffect(() => {
    if (!visible) {
      setQuestion('')
      setUser({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      })
    }
  }, [visible]);


  const onCloseContact = () => {
    onClose()
  }
  const changeQuestion = (e) => {
    setQuestion(e.target.value)
  }
  const onSubmitRequest = () => {
    dispatch(setLoading(true));
    API.createRequest({
      appId,
      description: question,
      ...visible.type ? visible : {},
      ...!isLoggedIn ? user : {}
    }).then(res => {
      dispatch(setLoading(false));
      if (res.code == 201) {
        store.addNotification({
          message: 'Submitted successfully!',
          type: 'success',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
        onClose(true)
        return
      }
      store.addNotification({
        message: 'Failed!',
        type: 'warning',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
    }).catch(() => {
      store.addNotification({
        message: 'Failed!',
        type: 'warning',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true,
        },
      });
      dispatch(setLoading(false));
    });
  }

  const btnDisabled = !question ||
    (!isLoggedIn && (!isEmail(user.email) || !user.firstName || !user.lastName || !user.phone));

  let title = formatMessage({ id: "applications.contact.title" });
  let description = formatMessage({ id: "applications.contact.subtitle" })
  let commentDescp = formatMessage({ id: "applications.contact.question" })

  if (visible?.type == "fab") {
    title = "VOUS AVEZ UNE QUESTION ";
  } else if (visible?.type) {
    description = <>Vous avez une question sur le produit <strong>"{visible.title}"</strong>, notre équipe est à votre dispositon pour y répondre.</>
    commentDescp = "Vous pouvez ajouter un commentaire et notre équipe revient vers vous dans les plus brefs délais"
  }

  return (
    <Modal open={!!visible} onClose={onCloseContact}>
      <Card className={classes.modal}>
        <CardHeader
          component={() => {
            return (
              <div className={classes.header}>
                <div>
                  <Typography className={classes.headertitle}>{title}</Typography>
                </div>
                <CloseIcon className={classes.closeIcon} onClick={onCloseContact} />
              </div>
            )
          }}
        />
        <CardContent>
          <Typography className={classes.subtitle}>{description}</Typography>

          {
            !isLoggedIn &&
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "first_name" })}</Typography>
                  <TextField
                    fullWidth
                    value={user.firstName}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        firstName: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "last_name" })}</Typography>
                  <TextField
                    fullWidth
                    value={user.lastName}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        lastName: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "email" })}</Typography>
                  <TextField
                    fullWidth
                    value={user.email}
                    onChange={e => {
                      e.persist();
                      setUser(prev => ({
                        ...prev,
                        email: e.target.value
                      }))
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.label}>{formatMessage({ id: "phone" })}</Typography>
                  <MuiPhoneNumber
                    defaultCountry={'fr'}
                    onChange={value => {
                      setUser(prev => ({
                        ...prev,
                        phone: value
                      }))
                    }}
                    value={user.phone}
                    name="phone"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </>
          }
          <br />
          <Typography className={classes.label}>{commentDescp}</Typography>
          <TextField
            fullWidth
            multiline
            value={question}
            onChange={changeQuestion}
            variant="outlined"
            rows={4}
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            onClick={onSubmitRequest}
            style={{ borderRadius: 100 }}
            disabled={btnDisabled}
          >
            {formatMessage({ id: "applications.contact.submit" })}
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

export default injectIntl(ContactModal);
