import {
    TextField,
    Typography,
    Tooltip
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    root: {

    },
}));

const CustomPasswordInput = ({
    label,
    className,
    required,
    labelStyle,
    endAdornment,
    InputProps,
    ...rest
}) => {
    const classes = useStyles();
    const hint = `Création de votre mot de passe :
    • Longueur : les mots de passe doivent comporter au moins 8 caractères.
    • Complexité : les mots de passe doivent inclure une combinaison de lettres majuscules, de lettres minuscules, de chiffres et de caractères spéciaux (par exemple !, @, #, $, %, ^, &, *).`;
    return (
        <>
            <TextField
                {...rest}
                InputProps={{
                    ...InputProps,
                    endAdornment: <BlueOnGreenTooltip title={<span style={{ whiteSpace: 'pre-line' }}>{hint}</span>}>
                        <InfoOutlinedIcon style={{
                            cursor: "pointer",
                            marginRight: 4
                        }} />
                    </BlueOnGreenTooltip>
                }}
            />
        </>
    );
};

export default CustomPasswordInput;

const BlueOnGreenTooltip = withStyles({
    tooltip: {
        color: "#263238",
        backgroundColor: "white",
        fontSize: 12,
        fontWeight: "bold",
        boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)"
    }
})(Tooltip);
