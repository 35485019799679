import moment from "moment";
import MomentUtils from "@date-io/moment";
import React, { useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/fr";
import { injectIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { localeSelector } from 'redux/selectors';

function MomentLocalizationExample({ intl: { formatMessage }, ...rest }) {
    const locale = useSelector(localeSelector);
    useEffect(() => {
        moment.locale(locale);
    }, [locale])
    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
            <DatePicker
                {...rest}
                cancelLabel={formatMessage({ id: "datepicker.cancel" })}
                clearLabel={formatMessage({ id: "datepicker.clear" })}
                okLabel={formatMessage({ id: "datepicker.ok" })}
            />
        </MuiPickersUtilsProvider>
    );
}

export default injectIntl(MomentLocalizationExample);
