/*
 *
 * LanguageProvider reducer
 *
 */

import { CHANGE_LOCALE, ACTION_UPDATE_CHARTS } from '../constants';
import { DEFAULT_LOCALE } from 'i18n';

export const initialState = {
  locale: DEFAULT_LOCALE,
  charts: ['invoices', 'daysoftrainees', 'duedateoftrainees']
};

function languageProviderReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_UPDATE_CHARTS:
      return { ...state, charts: action.payload };
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale };
    default:
      return state;
  }
}

export default languageProviderReducer;
