import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';

import {
    makeStyles,
    Typography,
    Paper,
    IconButton,
    TextField,
    Grid
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { injectIntl, FormattedMessage } from 'react-intl';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import useRouter from 'utils/useRouter';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: theme.spacing(2),
        position: 'relative'
    },
    icon: {
        width: theme.spacing(2),
        height: theme.spacing(2),
    },
    search: {
        position: 'absolute',
        right: 0,
        top: 0,
        minWidth: 350,
        borderWidth: 2,
        borderColor: theme.palette.app.grey,
        borderStyle: 'solid',
        borderRadius: theme.spacing(1),
        padding: `${theme.spacing(0.2)}px ${theme.spacing(1.5)}px`,
        fontSize: 12,
        backgroundColor: theme.palette.background.light
    },
    title: {
        fontSize: 25,
        color: '#030F40',
        fontWeight: 'bold',
        lineHeight: '40px'
    },
    subtitle: {
        color: '#6E7697'
    },
    root: {
        padding: '0px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
        margin: theme.spacing(1, 2),
        backgroundColor: 'white',
        borderRadius: 50,
        boxShadow: 'none',
        border: '1px solid #D3DAF6',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        color: 'black'
    },
    iconButton: {
        padding: `6px 10px`,
        color: '#BAC8BF',
        paddingRight: 0
    },
    divider: {
        height: 28,
        margin: 4,
    },
    formControl: {
        minWidth: 100,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        color: '#BAC8BF',
        fill: 'white'
    },
    dropIcon: {
        fill: theme.palette.app.primary
    },
    innerinput: {
        color: 'black',
        '&::placeholder': {
            color: '#BAC8BF'
        }
    },
    backBtn: {
        padding: 7,
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    }
}));

const Header = ({ title, hideSubtitle, searchPlaceholder, className, keyword, setKeyword }) => {
    const classes = useStyles();
    const router = useRouter();
    return (
        <div className={clsx(classes.container, className)}>
            <Grid container justify="space-between" alignItems="center">
                <div>
                    <Grid container alignItems='flex-start'>
                        <IconButton onClick={() => router.history.push("/catalogue")} className={classes.backBtn}><ArrowBackIosIcon /></IconButton>&nbsp;
                        <div>
                            <Typography className={classes.title}>{title ? title : <FormattedMessage id="applications.title" />}</Typography>
                            {!hideSubtitle && <Typography className={classes.subtitle}><FormattedMessage id="applications.subtitle" /></Typography>}
                        </div>
                    </Grid>
                </div>
                <Paper component="form" className={classes.root}>
                    <IconButton className={classes.iconButton} aria-label="menu">
                        <SearchIcon />
                    </IconButton>
                    <TextField
                        className={classes.input}
                        placeholder={searchPlaceholder || "Rechercher une solution"}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        InputProps={{
                            disableUnderline: true,
                            classes: { input: classes.innerinput }
                        }}
                        value={keyword}
                        onChange={e => {
                            e.persist()
                            setKeyword(e.target.value)
                        }}
                    />
                </Paper>
            </Grid>
        </div>
    );
};

Header.propTypes = {
    className: PropTypes.any,
    searchWord: PropTypes.string,
    setSearchWord: PropTypes.func.isRequired,
};

Header.defaultProps = {
    className: null,
};

export default Header;
