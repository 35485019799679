import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
    makeStyles,
    Divider,
    Button,
    Modal,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    TextField,
    MenuItem,
    FormGroup,
    FormHelperText,
    Typography,
    colors,
    IconButton
} from '@material-ui/core';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { useDispatch } from 'react-redux';
import { setLoading } from 'redux/actions/app';
import { ACTION_STATUSES } from 'config';
import * as API from 'services/api';
import {
    formatDateYYYYMMDD,
    getDateFromYYYYMMDD,
    MONTHS_STRINGS,
    WEEKDAYS_LONG,
    WEEKDAYS_SHORT,
} from 'utils';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { ReactComponent as TrashBinIcon } from 'assets/svgs/TrashBin.svg';
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextInput, CustomCommonSelect, CustomDatePicker } from 'components'
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { MultiLangDatePicker } from 'components'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { store } from 'react-notifications-component';

const useStyles = makeStyles(theme => ({
    modal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
        boxShadow: theme.shadows[20],
        width: 700,
        maxHeight: '100%',
        overflowY: 'auto',
        maxWidth: '100%',
        borderRadius: 10
    },
    field: {
        marginBottom: theme.spacing(1),
    },
    dayPicker: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        '& .DayPickerInput': {
            '& input': {
                outline: 'none',
                fontSize: 14,
                border: 'none',
                borderBottomWidth: 1,
                borderBottomColor: 'rgba(0,0,0,0.42)',
                borderBottomStyle: 'solid',
                paddingBottom: 6,
                paddingTop: 7,
                '&:hover': {
                    borderBottom: '1px solid #3f51b5',
                },
            },
        },
    },
    header: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        width: theme.spacing(2),
        height: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(3),
        cursor: 'pointer',
        width: theme.spacing(3),
        height: theme.spacing(3),

        fill: colors.blueGrey[900],

        '& path': {
            fill: colors.blueGrey[900],
        },

        '&:hover': {
            opacity: 0.5,
        },
    },
    actions: {
        marginBottom: theme.spacing(2),
        justifyContent: 'flex-end'
    },
    removeButton: {
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(1),
        transition: '0.3s',
        '&:hover': {
            opacity: 0.8,
        },
    },
    cardHeader: {
        backgroundColor: theme.palette.app.primary
    },
    headerTitle: {
        color: 'white',
        fontSize: 20,
        lineHeight: '25px'
    },
    saveBtn: {
        borderRadius: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    backdrop: {
        backgroundColor: `white !important`,
        opacity: 0.9
    },
}));

const ActionModal = ({
    intl: { formatMessage },
    visible,
    onClose,
}) => {
    const classes = useStyles();
    const [formState, setFormState] = useState({
        gender: '',
        firstName: '',
        lastName: '',
        birth: '',
    });

    const dispatch = useDispatch();

    const onFieldChange = event => {
        if (event.persist) {
            event.persist();
        }
        setFormState(formState => ({
            ...formState,
            [event.target.name]: event.target.value,
        }));
    };

    const isDisabled = Object.keys(formState)
        .map(key => formState[key] === '')
        .reduce((e1, e2) => e1 || e2);

    const onSubmit = () => {
        dispatch(setLoading(true));
        API.createEmployee(formState)
            .then(res => {
                dispatch(setLoading(false));
                if (res.code == 201) {
                    onClose(true)
                    store.addNotification({
                        message: 'created successfully!',
                        type: 'success',
                        container: 'top-right',
                        animationIn: ['animated', 'fadeIn'],
                        animationOut: ['animated', 'fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: true,
                        },
                    });
                }
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    return (
        <Modal open={visible} onClose={()=>onClose()} BackdropProps={{ className: classes.backdrop }}>
            <Card className={classes.modal}>
                <CardHeader
                    title={<FormattedMessage
                        id={'employee.add'}
                    />}
                    titleTypographyProps={{ className: classes.headerTitle }}
                    className={classes.cardHeader}
                    action={
                        <IconButton onClick={()=>onClose()}>
                            <CloseIcon style={{ fill: 'white' }} />
                        </IconButton>
                    }
                />
                <CardContent>
                    {Object.keys(formState).map(key => {
                        if (key === 'birth') {
                            return (
                                <CustomDatePicker
                                    key={key}
                                    variant="outlined"
                                    autoOk={true}
                                    label={formatMessage({ id: "employee.birth" })}
                                    fullWidth
                                    inputVariant="outlined"
                                    disableToolbar
                                    format="DD/MM/YYYY"
                                    name="birth"
                                    defaultValue={formState.date || null}
                                    value={formState.date || null}
                                    onChange={date => {
                                        setFormState(formState => ({
                                            ...formState,
                                            birth: date,
                                        }));
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputProps={{ endAdornment: <CalendarTodayIcon /> }}
                                    clearable
                                    maxDate={new Date()}
                                />
                            );
                        }
                        if (key === 'gender') {
                            return (
                                <CustomCommonSelect
                                    select
                                    className={classes.field}
                                    fullWidth
                                    variant="outlined"
                                    key={key}
                                    name={key}
                                    value={formState[key]}
                                    onChange={onFieldChange}
                                    label={formatMessage({
                                        id: `employee.${key}`,
                                    })}
                                //error={formState[key] === ''}
                                >
                                    <MenuItem key={"female"} value={"female"} style={{ backgroundColor: 'white' }}>
                                        {formatMessage({ id: "cart.gender.female" })}
                                    </MenuItem>
                                    <MenuItem key={"male"} value={"male"} style={{ backgroundColor: 'white' }}>
                                        {formatMessage({ id: "cart.gender.male" })}
                                    </MenuItem>
                                </CustomCommonSelect>
                            );
                        }
                        return (
                            <CustomTextInput
                                className={classes.field}
                                fullWidth
                                variant="outlined"
                                key={key}
                                name={key}
                                value={formState[key]}
                                onChange={onFieldChange}
                                label={formatMessage({
                                    id: `employee.${key}`,
                                })}
                            //error={formState[key] === ''}
                            />
                        );
                    })}
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button variant="contained" onClick={onSubmit} disabled={isDisabled} color="primary" className={classes.saveBtn}>
                        <SaveIcon className={classes.icon} />
                        <FormattedMessage id="save" />
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
};

ActionModal.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }),
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

ActionModal.defaultProps = {
    actionId: null,
};

export default injectIntl(ActionModal);
