import React, { Fragment, Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loggedInSelector, appLoadingSelector } from 'redux/selectors';
import Loader from 'components/Loader';

const useStyles = makeStyles(theme => ({
  content: {
    height: '100vh',
    ///backgroundImage: 'url(/images/background.jpg)',
    //backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white'
  },
}));

const AuthLayout = props => {
  const { route } = props;

  const classes = useStyles();

  const isLoggedIn = useSelector(loggedInSelector);
  const isLoading = useSelector(appLoadingSelector);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
        {isLoading && <Loader />}
      </main>
    </Fragment>
  );
};

AuthLayout.propTypes = {
  route: PropTypes.object,
};

export default AuthLayout;
