import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, CardHeader, Card, CardContent, IconButton, Typography } from '@material-ui/core';
import Header from './header'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '5%',
        left: '10%',
        overflow: 'scroll',
        height: '100vh',
        display: 'flex',
        zIndex: '1200 !important'
    },
    backdrop: {
        backgroundColor: `${theme.palette.app.primary} !important`,
        opacity: 0.8
    },
    inner: {
        width: '100%',
        marginLeft: '20%',
        marginRight: '20%',
        marginTop: 'auto',
        marginBottom: 'auto',
        height: '90vh',
        overflow: 'scroll',
        //display: 'flex',
        backgroundColor: '#F2F5FB',
        '&:focus': {
            outline: 'none !important'
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        position: 'relative',
        borderRadius: 10
    },
    content: {
        flex: 1,
        //marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column'
    },
    CardContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        padding: theme.spacing(3)
    },
    footer: {
        padding: theme.spacing(3),
        maxHeight: 80
    }
}));

const ModalWrap = ({ open, title, closeModal, backdropStyle, footer, rightComponent, headerDarkmode, children }) => {
    const classes = useStyles();
    return (
        <Modal
            className={classes.root}
            open={open}
            onClose={() => closeModal()}
            aria-labelledby="users-modal-title"
            aria-describedby="users-modal-description"
            //disableBackdropClick
            BackdropProps={backdropStyle ? backdropStyle : { className: classes.backdrop }}
        >
            <Card className={classes.inner}>
                <Header
                    headerDarkmode={headerDarkmode}
                    title={title}
                    rightComponent={rightComponent}
                    closeModal={() => closeModal()}
                />
                <CardContent className={classes.CardContent}
                    style={{ minHeight: footer ? 'calc(100% - 152px)' : 'auto' }}>
                    <div className={classes.content}>
                        {children}
                    </div>
                </CardContent>
                {
                    footer &&
                    <footer className={classes.footer}>
                        {footer}
                    </footer >
                }
            </Card>
        </Modal >
    );
};
export default ModalWrap;
