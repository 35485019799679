import React from 'react';
import { makeStyles, Hidden } from '@material-ui/core';

const bannerHeight = 150;

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    padding: theme.spacing(4, 5)
  },
  topBar: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: bannerHeight,
    backgroundImage: 'url(/images/banner.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  content: {
    margin: 'auto',
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return (
    <Hidden smDown>
      <div className={classes.container}>
        <div className={classes.content}>{children}</div>
      </div>
    </Hidden>
  );
};
