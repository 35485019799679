import React, { useState, useEffect } from 'react';
import {
    Popover,
    makeStyles,
    Typography,
    List,
    ListItem,
    IconButton,
    ListItemText,
    Paper,
    InputBase
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import SearchIcon from '@material-ui/icons/Search'
import CartIcon from '@material-ui/icons/ShoppingCart'
import * as API from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { appProfileSelector, localeSelector } from 'redux/selectors';
import { ROLE_APAVE_CLIENT } from 'config';
import { includesSearchWord } from 'utils';
import { setLoading, updateApp } from 'redux/actions/app';

const useStyles = makeStyles(theme => ({
    popover: {
        overflow: 'hidden'
    },
    container: {
        width: 400,
        height: 400
    },
    content: {
        backgroundColor: 'white',
        padding: 20
    },
    title: {
        color: theme.palette.app.primary,
        fontWeight: 'bolder',
        fontSize: 18,
        marginBottom: 0,
    },
    searchRoot: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#EDEDED',
        marginTop: 16,
        marginBottom: 8
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    iconButton: {
        padding: 10,
        backgroundColor: '#000049',
        color: 'white',
        borderRadius: 4,
        '&:hover': {
            backgroundColor: '#00004955 !important'
        }
    },
}));
const ClientSelect = (props) => {
    const { id, open, anchorEl, handleClose, setClientName } = props
    const classes = useStyles();
    const [clients, setClients] = useState([]);
    const { role, clientId, id: userId, password, ...rest } = useSelector(appProfileSelector);
    const [keyword, setKeyword] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        if (role == ROLE_APAVE_CLIENT) {
            API.listClients().then(res => {
                if (res.code == 200) {
                    setClients(res.data)
                    const client = res.data.find(el => el.id == clientId);
                    if (client) setClientName(client.company)
                }
            })
        }
    }, []);

    const onChangeCompany = (newid) => (e) => {
        API.updateProfile(userId, {
            ...rest,
            clientId: newid
        })
            .then(res => {
                dispatch(setLoading(false));
                if (res.code === 200) {
                    dispatch(
                        updateApp({
                            profile: res.user,
                        }),
                    );
                    window.location.reload();
                }
            })
            .catch(err => {
                dispatch(setLoading(false));
            });
    }
    let datasource = clients;
    if (keyword && keyword.length > 0) datasource = datasource.filter(item => includesSearchWord(item.company, keyword));

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{ paper: classes.popover }}
        >
            <div className={classes.container}>
                <div className={classes.content}>
                    <Typography variant="h5" className={classes.title} paragraph>
                        Sélectionner un client
                    </Typography>
                    <Paper className={classes.searchRoot}>
                        <InputBase
                            className={classes.input}
                            placeholder="Rechercher"
                            value={keyword}
                            onChange={e => {
                                e.persist();
                                setKeyword(e.target.value)
                            }}
                        />
                        <IconButton type="submit" className={classes.iconButton}>
                            <SearchIcon style={{ width: 22, height: 22 }} />
                        </IconButton>
                    </Paper>
                    <List className={classes.scrollview}>
                        {datasource.map(client => (
                            <ListItem
                                key={client.id}
                                button
                                selected={clientId === client.id}
                                disabled={clientId === client.id}
                                onClick={onChangeCompany(client.id)}
                            >
                                <ListItemText primary={client.company} />
                            </ListItem>
                        ))}
                    </List>
                </div>
            </div>
        </Popover >
    );
};

export default ClientSelect;
