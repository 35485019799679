import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
}));
const Chart = props => {
  const { data, labels, className,title, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0
        },
      },
    },
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant='h6' style={{ textAlign: 'center' }}><strong>{title}</strong></Typography>
      <Bar
        data={data}
        options={options}
      />
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string
};

export default Chart;
