import * as actionTypes from '../constants';

export const updateAuth = payload => ({
  type: actionTypes.ACTION_UPDATE_AUTH,
  payload,
});

export const initAuth = payload => ({
  type: actionTypes.ACTION_INIT_AUTH,
  payload,
});

export const login = payload => ({
  type: actionTypes.ACTION_LOGIN,
  payload,
});

export const signup = payload => ({
  type: actionTypes.ACTION_SIGNUP,
  payload,
});

export const logout = () => ({
  type: actionTypes.ACTION_LOGOUT,
});
