import React from 'react';
import { makeStyles, Typography, colors, Divider, Box } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { ReactComponent as IconEmoji } from 'assets/svgs/emoji.svg';
import ServicePage from 'pages/Applications';

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(1),
    color: colors.grey[900],
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.app.primary,
    marginBottom: theme.spacing(2),
    fontWeight: '600',
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 15,
    height: 15,
    fill: colors.grey[900],
    '& path': {
      fill: colors.grey[900],
    },
  },
  button: {
    borderRadius: theme.spacing(1.5),
    padding: '4px 16px',
    textTransform: 'none',
  },
  serviceGroup: {
    fontWeight: '700',
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    borderTop: '1px solid #E5E3F2',
    width: '100%'
  },
  placeholder: {
    paddingLeft: theme.spacing(3)
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  logo: {
    maxHeight: 32,
    margin: theme.spacing(1, 0),
    paddingLeft: theme.spacing(3)
  },
  onePermission: {
    textAlign: 'center'
  },
  emptyWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(6)
  },
  emoji: {
    width: 65,
    height: 65,
    marginBottom: theme.spacing(1)
  },
  emptyTitle: {
    margin: theme.spacing(1, 0)
  },
  togglebtngroup: {
    marginBottom: theme.spacing(4),
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block'
  },
  togglebtn: {
    border: '1px solid #030F40 !important',
    borderRadius: '30px !important',
    marginRight: theme.spacing(2),
    padding: '10px 30px',
    color: `${theme.palette.app.primary} !important`
  },
  selectedBtn: {
    fontWeight: 'bold !important',
    color: `white !important`,
    backgroundColor: `${theme.palette.app.primary} !important`
  },
  btnLabel: {
    fontSize: '12px !important'
  },
}));

const PermissionsInfo = ({
  intl: { formatMessage },
  apaves,
  data: { services: permissions },
}) => {
  const classes = useStyles();
  const activated_apaves = apaves.filter(service => permissions.find(item => item.allowed && item.appId == service.appId))

  return (
    <div>
      {
        activated_apaves.length <= 0 &&
        <>
          <Box className={classes.emptyWrap}>
            <IconEmoji className={classes.emoji} />
            <Typography variant='h3' className={classes.emptyTitle}>
              <strong>{formatMessage({ id: "profile.service.emptyTitle" })}</strong>
            </Typography>
            <Typography className={classes.emptyDesp}>
              {formatMessage({ id: "profile.service.emptyDesp" })}
            </Typography>
          </Box>
          <Divider />
          <br />
        </>
      }
      <ServicePage
        disablePadding
        hideBookmark
        title={formatMessage({ id: "profile.service.title" })}
        hideSubtitle
        searchPlaceholder={formatMessage({ id: "profile.service.search" })}
      />
    </div >
  );
};

export default injectIntl(PermissionsInfo);
