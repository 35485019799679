import {
    Card,
    CardContent,
    Typography,
    Grid,
    TableCell,
    TableRow,
    Modal,
    CardHeader,
    TableBody,
    Button,
    Table,
    TableContainer,
    Chip,
    IconButton,
    makeStyles,
    Avatar
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment'
import { LEAD_EXTERNAL_STATUSES } from 'config'
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextInput, CustomTable } from 'components'
import { useDispatch } from 'react-redux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: '5%',
        left: '10%',
        overflow: 'scroll',
        height: '100vh',
        display: 'flex',
        zIndex: '3000 !important'
    },
    backdrop: {
        backgroundColor: `white !important`,
        opacity: 0.9
    },
    inner: {
        width: '100%',
        marginLeft: '20%',
        marginRight: '20%',
        marginTop: 'auto',
        marginBottom: 'auto',
        height: '90vh',
        overflow: 'scroll',
        //display: 'flex',
        backgroundColor: '#F1F3F9',
        '&:focus': {
            outline: 'none !important'
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        position: 'relative',
        borderRadius: 10
    },
    content: {
        marginTop: theme.spacing(2),
    },
    closebtn: {
        borderRadius: 100
    },
    cardHeader: {
        backgroundColor: theme.palette.app.primary
    },
    headerTitle: {
        color: 'white',
        fontSize: 20,
        lineHeight: '25px'
    },
}));

const OrderDetails = props => {
    const {
        intl: { formatMessage },
        open: data,
    } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const closeModal = () => {
        props.handleClose()
    }
    const documentskeys = [
        "gender",
        "firstName",
        "lastName",
        "birth",
        "picture",
        "siret",
        "company",
    ];
    const documentTableColumns = [
        {
            name: "gender",
            label: "Civilité",
            options: {
                display: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return formatMessage({ id: "cart.gender." + value })
                }
            }
        },
        {
            name: "lastName",
            label: "Nom",
            options: {
                display: true,
                sort: true,
            }
        },
        {
            name: "firstName",
            label: "Prénom",
            options: {
                display: true,
                sort: true,
            }
        },
        {
            name: "birth",
            label: "Date de naissance",
            options: {
                display: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return value ? moment(value).format("DD/MM/YYYY") : ""
                }
            }
        },
        {
            name: "company",
            label: "Entreprise",
            options: {
                display: true,
                sort: true
            }
        },
        {
            name: "picture",
            label: "Photo",
            options: {
                display: true,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    return value ? <Avatar alt={"photo"} src={value} style={{ width: 30, height: 30 }} /> : ""
                }
            }
        },
    ];
    return (
        <Modal
            className={classes.root}
            open={!!data}
            onClose={closeModal}
            aria-labelledby="users-modal-title"
            aria-describedby="users-modal-description"
            //disableBackdropClick
            BackdropProps={{ className: classes.backdrop }}
        >
            <Card className={classes.inner}>
                <CardHeader
                    title={formatMessage({ id: "details" })}
                    titleTypographyProps={{ className: classes.headerTitle }}
                    className={classes.cardHeader}
                    action={
                        <IconButton onClick={closeModal}>
                            <CloseIcon style={{ fill: 'white' }} />
                        </IconButton>
                    }
                />
                <CardContent>
                    <div>
                        <div className={classes.section}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <CustomTextInput
                                        fullWidth
                                        variant="outlined"
                                        label={'PRODUCT:'}
                                        value={data?.source.title}
                                        disabled
                                        multiline
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextInput
                                        fullWidth
                                        variant="outlined"
                                        label={'DATE:'}
                                        value={moment(data?.createdAt).format("DD/MM/YYYY")}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextInput
                                        fullWidth
                                        variant="outlined"
                                        label={"Quantité:".toUpperCase()}
                                        value={data?.source.quantity || "1"}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextInput
                                        fullWidth
                                        variant="outlined"
                                        label={'MONTANT:'}
                                        value={data?.source.price + "€"}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextInput
                                        fullWidth
                                        variant="outlined"
                                        label={'STATUT:'}
                                        value={formatMessage({
                                            id: 'action.status.' + (!LEAD_EXTERNAL_STATUSES.includes(data?.statusExternal) ? LEAD_EXTERNAL_STATUSES[0] : data?.statusExternal),
                                        })}
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        <br />
                        <br />
                        {
                            data && data.source.trainees && data.source.trainees.length > 0 &&
                            <>
                                <Typography variant="h5"><strong>Participants</strong></Typography>
                                <CustomTable
                                    columns={documentTableColumns}
                                    data={data.source.trainees}
                                    options={{
                                        pagination: false,
                                        print: false,
                                        download: false,
                                        selectableRows: 'none',
                                        search: true,
                                        filter: true,
                                        viewColumns: false,
                                        elevation: 0,
                                        filter: false,
                                        search: false,
                                        textLabels: {
                                            body: {
                                                noMatch: <div style={{ textAlign: 'center' }}>
                                                    <ErrorOutlineIcon />
                                                    <br />
                                                    <Typography style={{ color: "#061024", fontSize: 13 }}>
                                                        Aucun document disponible à ce jour.
                                                    </Typography>
                                                </div>
                                            }
                                        }
                                    }}
                                />
                            </>
                        }
                    </div>
                </CardContent>
            </Card>
        </Modal>
    );
};

OrderDetails.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }),
};

export default injectIntl(OrderDetails);
