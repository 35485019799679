import React from 'react';
import { makeStyles, withStyles, Tabs, Tab } from '@material-ui/core';

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

export const StyledTabs = withStyles({
    indicator: {
        display: 'none',
        justifyContent: 'center',
        backgroundColor: '#040f40',
        width: 140,
        height: 4,
        borderRadius: 4,
        '& > span': {
            backgroundColor: '#635ee7',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: theme.palette.app.primary,
        fontWeight: '600',
        fontSize: theme.typography.pxToRem(15),
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: theme.spacing(2),
        borderRadius: 30,
        border: '1px solid #030F40',
        '&:focus': {
            opacity: 1,
        },
    },
    wrapper: {
        alignItems: 'center'
    },
    selected: {
        color: 'white',
        backgroundColor: theme.palette.app.primary,
    }
}))((props) => <Tab disableRipple {...props} />);
