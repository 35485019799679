import React from 'react';
import {
  makeStyles,
  colors,
  Typography,
  TextField,
  Grid,
  Card,
  CardContent,
  Button
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomPasswordInput from 'components/CustomPasswordInput';
import { getPasswordSecureWarning } from 'utils/validations';

const useStyles = makeStyles(theme => ({
  field: {
    flex: 1
  },
  title: {
    color: theme.palette.app.primary,
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 15,
    padding: '16px !important'
  },
  label: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E3F2',
    paddingBottom: theme.spacing(2)
  },
  subtitle: {
    color: '#0E1927'
  },
  button: {
    borderRadius: theme.spacing(0.5),
    padding: '4px 16px',
    textTransform: 'none',
    marginTop: theme.spacing(2)
  },
}));

const PasswordSection = ({
  data: { newPassword, newPasswordConfirm },
  handleChange,
  intl: { formatMessage },
  disabled,
  onSave
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent>
        <div className={classes.header}>
          <div>
            <Typography variant="h4" className={classes.title}>
              <FormattedMessage id="security" />
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              <FormattedMessage id="home.profile.password_condition" />
            </Typography>
          </div>
        </div>
        <div>
          <Grid container display="flex" spacing={4}>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.label}>{formatMessage({ id: 'home.profile.new_password' })}</Typography>
              <CustomPasswordInput
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                error={newPassword && getPasswordSecureWarning(newPassword)}
                className={classes.field}
                type="password"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.label}>{formatMessage({ id: 'home.profile.new_password_confirm' })}</Typography>
              <TextField
                className={classes.field}
                name="newPasswordConfirm"
                value={newPasswordConfirm}
                onChange={handleChange}
                error={
                  newPassword !== newPasswordConfirm ||
                  (newPassword !== '' && newPassword.length < 6)
                }
                type="password"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
        <Grid container justify="flex-end" >
          <Button variant="contained" classes={{ root: classes.button }} disabled={disabled} onClick={onSave}>
            <FormattedMessage id="save" />
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default injectIntl(PasswordSection);
