import * as actionTypes from '../constants';

const initialState = {
  inProducts: [],
  apaveProducts: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_UPDATE_INPRODUCTS:
      return { ...state, inProducts: action.payload };
    case actionTypes.ACTION_UPDATE_APAVEPRODUCTS:
      return { ...state, apaveProducts: action.payload };
    default:
      return state;
  }
}
