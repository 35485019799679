import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { makeStyles, Hidden, Divider, Typography, Modal, Card, CardHeader, CardContent, TextField, CardActions, Button, colors, Grid } from '@material-ui/core';
import { appProfileSelector } from 'redux/selectors';
import { ApplicationContent, Header } from './components';
import { setLoading } from 'redux/actions/app';
import { useSelector, useDispatch } from 'react-redux';
import * as API from 'services/api';
import { groupBy, includesSearchWord } from 'utils';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { store } from 'react-notifications-component';
import Searchbar from './searchbar'
import CloseIcon from '@material-ui/icons/Close';
import { ContactModal } from 'components/Modals'

const HIDDEN_GROUP = ["ESPACE CLIENT"]
const all_button = 'all'

const useStyles = makeStyles(theme => ({
  container: {
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px`,
  },
  mainContent: { marginTop: theme.spacing(2), minHeight: '100vh' },
  togglebtngroup: {
    marginBottom: theme.spacing(4),
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'block'
  },
  togglebtn: {
    border: '1px solid #030F40 !important',
    borderRadius: '30px !important',
    marginRight: theme.spacing(2),
    padding: '10px 30px',
    color: `${theme.palette.app.primary} !important`
  },
  selectedBtn: {
    fontWeight: 'bold !important',
    color: `white !important`,
    backgroundColor: `${theme.palette.app.primary} !important`
  },
  btnLabel: {
    fontSize: '12px !important'
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 800,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    borderRadius: 10
  },
  field: {
    marginBottom: theme.spacing(1),
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 22,
    cursor: 'pointer',
    width: theme.spacing(4),
    height: theme.spacing(4),

    fill: 'white',

    '& path': {
      fill: 'white',
    },
  },
  actions: {
    marginBottom: theme.spacing(1)
  },
  header: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    display: 'flex',
    backgroundColor: theme.palette.app.primary
  },
  icon: {
    width: theme.spacing(2.1),
    height: theme.spacing(2.1),
    marginRight: theme.spacing(1),

    fill: 'white',
    '& path': {
      fill: 'white',
    },
  },
  headertitle: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    lineHeight: '40px',
    marginRight: theme.spacing(2)
  },
  subtitle: {
    color: '#464F73'
  },
  label: {
    fontWeight: 'bold',
    color: theme.palette.app.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  }
}));

const Applications = ({ intl: { formatMessage }, disablePadding, hideBookmark, title, hideSubtitle, searchPlaceholder }) => {
  const classes = useStyles();
  const profile = useSelector(appProfileSelector);
  const dispatch = useDispatch();
  const [apaves, setApaves] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [formats, setFormats] = useState(all_button);
  const [datasource, setDatasource] = useState([]);
  const [visibleContact, setVisibleContact] = useState(false)
  const [question, setQuestion] = useState('')
  const [selAppId, setSelAppId] = useState()
  const [keyword, setKeyword] = useState('')

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  useEffect(() => {
    dispatch(setLoading(true));
    let new_bookmarks = localStorage.getItem('bookmarks')
    try {
      new_bookmarks = JSON.parse(new_bookmarks)
    } catch (error) {
      new_bookmarks = []
    }
    setBookmarks(new_bookmarks || []);
    API.listApaveApps()
      .then(res => {
        dispatch(setLoading(false));
        const data = res.data ? res.data.filter(apave => !HIDDEN_GROUP.includes(apave.group)) : []
        setApaves(data);
        setDatasource(data);
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    if (apaves.length > 0) {
      setDatasource([])
      setTimeout(() => {
        setDatasource(apaves.filter(item => formats == all_button || formats == item.group))
      }, 100);
    }
  }, [formats])

  const onBookMark = (id) => () => {
    let new_bookmarks = []
    if (bookmarks.includes(id)) {
      new_bookmarks = bookmarks.filter(item => item != id)
    } else new_bookmarks = [...bookmarks, id]
    setBookmarks(new_bookmarks)
    localStorage.setItem('bookmarks', JSON.stringify(new_bookmarks));
  }

  const onCloseContact = () => {
    setQuestion('')
    setVisibleContact(false)
  }
  const changeQuestion = (e) => {
    setQuestion(e.target.value)
  }
  const onContact = (appId) => () => {
    setSelAppId(appId)
    setVisibleContact(true)
  }

  const grouped_apaves = groupBy(apaves, 'group')
  const permissions = profile.services
  let enabled_apaves = []
  let disabled_apaves = []

  let filtered = datasource
  if (keyword && keyword.length > 0) filtered = filtered.filter(item => includesSearchWord(item.title, keyword))

  filtered.map(item => {
    if (!!permissions.find(permission => permission.allowed && permission.appId == item.appId)) {
      enabled_apaves.push(item)
    } else disabled_apaves.push(item)
  })

  const bookmarked_apaves = bookmarks.map(item => filtered.find(apave => apave.id == item)).filter(item => !!item)
  const groups = [all_button, ...Object.keys(grouped_apaves)]

  const service = apaves.find(item => item.appId == selAppId)

  return (
    <div className={classes.container} style={disablePadding ? { padding: 0 } : {}}>
      <Searchbar
        keyword={keyword}
        setKeyword={setKeyword}
        title={title}
        hideSubtitle={hideSubtitle}
        searchPlaceholder={searchPlaceholder}
      />
      {
        groups.length > 1 &&
        <ToggleButtonGroup value={formats} onChange={handleFormat} aria-label="text formatting" className={classes.togglebtngroup} exclusive>
          {
            groups.map(group => {
              return (
                <ToggleButton key={group} value={group} aria-label={group}
                  classes={{
                    root: classes.togglebtn,
                    selected: classes.selectedBtn,
                    label: classes.btnLabel
                  }}>
                  {group == 'all' ? formatMessage({ id: "profile.service.all" }) : group}
                </ToggleButton>
              )
            })
          }
        </ToggleButtonGroup>
      }
      {
        enabled_apaves && enabled_apaves.length > 0 &&
        <>
          <Header title={formatMessage({ id: "applications.ownservice" })} />
          <ApplicationContent
            data={enabled_apaves}
            active={true}
            onBookMark={onBookMark}
            bookmarks={bookmarks}
            onContact={onContact} />
          <br />
          <Divider />
        </>
      }
      {
        !hideBookmark && bookmarked_apaves && bookmarked_apaves.length > 0 &&
        <>
          <Header title={formatMessage({ id: "applications.likedservice" })} />
          <ApplicationContent
            data={bookmarked_apaves}
            onBookMark={onBookMark}
            bookmarks={bookmarks}
            onContact={onContact} />
          <br />
          <Divider />
        </>
      }
      {
        disabled_apaves && disabled_apaves.length > 0 &&
        <>
          <Header title={formatMessage({ id: "applications.apaveservice" })} />
          <div className={classes.mainContent}>
            <ApplicationContent
              animation='slide'
              data={disabled_apaves}
              onBookMark={onBookMark}
              bookmarks={bookmarks}
              onContact={onContact}
            />
          </div>
        </>
      }
      <ContactModal
        appId={selAppId}
        visible={visibleContact}
        onClose={onCloseContact}
      />
    </div>
  );
};

export default injectIntl(Applications);
