import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, colors, TextField, Typography, Grid, Card, CardContent } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { profileURL } from 'config';
import AvatarImage from 'assets/images/seul.png';
import { injectIntl, FormattedMessage } from 'react-intl';
import { isPhone } from 'utils/validations';
import MuiPhoneNumber from 'material-ui-phone-number';
import { ReactComponent as IconPhoto } from 'assets/svgs/add_photo.svg';

const avatarSize = 70;

const useStyles = makeStyles(theme => ({
  rounded: {
    backgroundColor: 'white',
    padding: 4,
    width: avatarSize,
    height: avatarSize,
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
  img: {
    width: avatarSize,
    height: avatarSize,
    backgroundColor: colors.grey[300],
    borderRadius: 10
  },
  hidden: {
    display: 'none',
  },
  field: {
    flex: 1
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 15,
    height: 15,
    fill: colors.grey[900],
    '& path': {
      fill: colors.grey[900],
    },
  },
  horizon: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.app.primary,
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  email: {
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
    lineHeight: '10px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 15,
    padding: '16px !important'
  },
  label: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E3F2'
  },
  subtitle: {
    color: '#0E1927'
  },
  photoIcon: {
    width: 30,
    height: 30
  }
}));

const ProfileBasic = ({
  data,
  handleChange,
  email,
  setFormState,
  intl: { formatMessage },
}) => {
  const { picture, firstName, lastName, position, file, fileSrc, phone } = data;
  const classes = useStyles();

  const isOnlineImage = picture !== undefined && picture !== '';
  const url = profileURL + picture;

  const fileInputRef = useRef();

  const onAvatar = () => {
    fileInputRef.current.click();
  };

  const onFileChange = event => {
    if (event.target.files.length > 0) {
      event.persist();

      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = function (e) {
        var img = new Image();
        img.onload = () => {
          if (img.width !== img.height || img.width !== 500) {
            return alert(
              'Merci de charger une photo de 500px par 500px au format jpg ou png.',
            );
          }
          setFormState(formState => ({
            ...formState,
            file,
            fileSrc: reader.result,
          }));
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const isError = value => value === undefined || value === '';

  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.title}>
                <FormattedMessage id="my_information" />
              </Typography>
              <Typography variant="h6" className={classes.subtitle}>
                {formatMessage({ id: "profile.basic.description" })}
              </Typography>
            </div>
            <Avatar variant="rounded" className={classes.rounded} onClick={onAvatar}>
              {
                (file || isOnlineImage) ?
                  <img
                    src={file ? fileSrc : isOnlineImage ? url : AvatarImage}
                    alt="avatar"
                    className={classes.img}
                  /> :
                  <IconPhoto className={classes.photoIcon}/>
              }
            </Avatar>
            <input
              type="file"
              className={classes.hidden}
              id="avatar-file"
              ref={fileInputRef}
              onChange={onFileChange}
              accept="image/png, image/jpeg"
            />
          </div>
          <div>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>Identifiant Email</Typography>
                <TextField
                  value={email}
                  disabled
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'phone' })}</Typography>
                <MuiPhoneNumber
                  className={classes.field}
                  defaultCountry={'fr'}
                  onChange={value => {
                    handleChange({
                      target: {
                        name: 'phone',
                        type: 'text',
                        value,
                      },
                    });
                  }}
                  value={phone}
                  error={isError(phone) || !isPhone(phone)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'first_name' })}</Typography>
                <TextField
                  name="firstName"
                  value={firstName}
                  onChange={handleChange}
                  error={isError(firstName)}
                  className={classes.field}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'last_name' })}</Typography>
                <TextField
                  className={classes.field}
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                  error={isError(lastName)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container display="flex" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.label}>{formatMessage({ id: 'position' })}</Typography>
                <TextField
                  className={classes.field}
                  name="position"
                  value={position}
                  onChange={handleChange}
                  error={isError(position)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>

              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

ProfileBasic.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  data: PropTypes.shape({
    picture: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    position: PropTypes.string,
    file: PropTypes.any,
    fileSrc: PropTypes.any,
  }),
  handleChange: PropTypes.func.isRequired,
  setFormState: PropTypes.func.isRequired,
};

export default injectIntl(ProfileBasic);
