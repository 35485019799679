import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
  colors,
  Button,
  Card,
  CardContent,
  Avatar
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactComponent as TelephoneIcon } from 'assets/svgs/profile_phone.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/svgs/Envelope.svg';
import { ReactComponent as UserIcon } from 'assets/svgs/profile_user.svg';
import { ReactComponent as LocationIcon } from 'assets/svgs/profile_location.svg';
import GoogleMapReact from 'google-map-react';

const useStyles = makeStyles(theme => ({
  field: {
    marginBottom: theme.spacing(1),
    color: '#0E1927',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.app.primary,
    marginBottom: theme.spacing(1),
    fontWeight: '600',
    textTransform: 'uppercase'
  },
  icon: {
    marginRight: theme.spacing(1),
    width: 20,
    height: 20,
    fill: '#8F97B9',
    '& path': {
      fill: '#8F97B9',
    },
  },
  button: {
    borderRadius: theme.spacing(0.5),
    padding: '4px 16px',
    textTransform: 'none',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 15,
    padding: '16px !important'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E5E3F2',
    marginBottom: theme.spacing(3)
  },
  avatar: {
    width: '100%',
    height: 220,
    borderRadius: 5
  }
}));

const AdditionalInfo = ({ intl: { formatMessage } }) => {
  const classes = useStyles();

  const email = 'email@agency.com';

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.header}>
              <div>
                <Typography variant="h4" className={classes.title}>
                  <FormattedMessage id="my_agency" />
                </Typography>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography paragraph className={classes.field}>
                  <EnvelopeIcon className={classes.icon} />
                  APAVE
                </Typography>
                <Typography paragraph className={classes.field}>
                  <LocationIcon className={classes.icon} />97-103 Boulevard Victor Hugo 93400 Saint-Ouen
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ height: 220, width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: '' }}
                    defaultCenter={{
                      lat: 59.95,
                      lng: 60.33
                    }}
                    defaultZoom={6}
                  >

                  </GoogleMapReact>
                </div>
              </Grid>
            </Grid>
            <Grid container justify="flex-start">
              <Button variant="contained" classes={{ root: classes.button }}>
                <FormattedMessage id="access" />
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.header}>
              <div>
                <Typography variant="h4" className={classes.title}>
                  <FormattedMessage id="my_advisor" />
                </Typography>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography paragraph className={classes.field}>
                  <UserIcon className={classes.icon} />
                  Benoît Legendre
                </Typography>
                <Typography paragraph className={classes.field}>
                  <TelephoneIcon className={classes.icon} />
                  +33 2 23 43 24 12
                </Typography>
                <Typography paragraph className={classes.field}>
                  <EnvelopeIcon className={classes.icon} />
                  {email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Avatar className={classes.avatar}></Avatar>
              </Grid>
            </Grid>
            <Grid container justify="flex-start">
              <Button
                href={`mailto:${email}`}
                variant="contained"
                classes={{ root: classes.button }}
              >
                <FormattedMessage id="contact" />
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  );
};

export default injectIntl(AdditionalInfo);
